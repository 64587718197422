<template>
  <div class="userLevelList">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>等级列表</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table :data="tableData" border style="width: 100%">
      <!-- <el-table-column align="center" type="selection"></el-table-column> -->
      <el-table-column
        align="center"
        prop="levelId"
        label="等级ID"
      ></el-table-column>
      <el-table-column align="center" prop="title" label="头衔">
        <template slot-scope="{ row }">
          <el-tooltip
            :enterable="false"
            class="item"
            effect="dark"
            content="点击修改头衔"
            placement="top"
          >
            <el-input
              class="input-new-tag"
              v-if="row.inputVisible"
              v-model="row.title"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm(row)"
              @blur="handleInputConfirm(row)"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="onChangeSort(row)"
              >{{ row.title }}</el-button
            >
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="integral"
        label="升级所需积分"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="relegation"
        label="保级所需积分"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="getIntegral"
        label="购物所得积分倍数"
      ></el-table-column>
      <el-table-column align="center" prop="backImg" label="等级背景图片">
        <template slot-scope="{ row }">
          <el-image
            style="width: 80px; height: 80px"
            :src="row.backImg"
            :preview-src-list="[row.backImg]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="discount"
        label="购物折扣"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="添加时间"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template slot-scope="{ row }">
          <el-button
            type="primary"
            size="mini"
            v-if="$store.state.powerList.indexOf('user:level:list:edit') !== -1"
            @click="onEditRow(row)"
            >编辑</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="onDelRow(row.levelId)"
            v-if="
              $store.state.powerList.indexOf('user:level:list:delete') !== -1
            "
            v-show="row.levelId !== 1"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="receive(row.levelId)"
           
            >赠卡设置</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-button
      class="btn"
      type="primary"
      @click="onAdd(0)"
      v-if="$store.state.powerList.indexOf('user:level:list:add') !== -1"
      >添加</el-button
    >
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>

    <el-dialog
      :title="addOrEditForm.lelvelId == 0 ? '添加' : '编辑'"
      :visible.sync="dialogVisible"
      width="40%"
      @close="onClosedialog"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="头衔">
          <el-input
            v-model="addOrEditForm.title"
            placeholder="请输入头衔名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="升级所需积分">
          <el-input
            @input="onInput"
            v-model.number="addOrEditForm.integral"
            placeholder="请输入所需积分"
          ></el-input>
        </el-form-item>
        <el-form-item label="保级所需积分">
          <el-input
            @input="onInput3"
            v-model.number="addOrEditForm.relegation"
            placeholder="请输入所需积分"
          ></el-input>
        </el-form-item>
        <el-form-item label="	购物获得积分倍数">
          <el-input
            @change="onInput2"
            v-model="addOrEditForm.getIntegral"
            placeholder="请输入倍数,可以为小数 默认1"
          ></el-input>
        </el-form-item>
        <el-form-item label="购物折扣 ">
          <el-input
            min="1"
            max="100"
            @input="onInput1"
            v-model.number="addOrEditForm.discount"
            placeholder="请输入折扣购物折扣, 1-100之间的正整数"
          ></el-input>
        </el-form-item>
   
        <el-form-item label="等级背景图片">
          <send-image
            :images="addOrEditForm.backImg"
            v-if="dialogVisible"
            @addImageStr="addImageStr"
          ></send-image>
          <!-- <input type="file" hidden ref="fileRef" @change="onFileChange" />
          <img
            v-if="addOrEditForm.backImg"
            style="width: 100px; height: 100px"
            :src="addOrEditForm.backImg"
          />
          <div class="elbtn el-icon-plus" @click="sendAvatar"></div> -->
          <span style="color: red">建议尺寸315*150px</span>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  userLevelList,
  addLevel,
  levelDetails,
  changeUserLevel,
  delLevel,
} from "../../../api/level";
import { sendFile } from "../../../api/sendFile";
import sendImage from "../../../components/sendImage.vue";
export default {
  components: { sendImage },
  name: "userLevelList",
  data() {
    return {
      tableData: [],
      pagination: {},
      dialogVisible: false,
      addOrEditForm: {
        lelvelId: "",
        title: "",
        integral: "",
        getIntegral: "",
        discount: "",
        backImg: "",
        relegation:"",
     
      },
      list: {
        currentPage: 1,
        pageSize: 5,
      },
    };
  },
  created() {
    this.getUserLevelList();
  },
  methods: {
    addImageStr(image) {
      this.addOrEditForm.backImg = image;
    },

    async getUserLevelList() {
      const { data } = await userLevelList(this.list);
      console.log(data);
      data.list.map((item) => {
        item.inputVisible = false;
      });
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    onDelRow(levelId) {
      this.$confirm("是否删除这条数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delLevel({
            levelId,
          });
          if (data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getUserLevelList();
          } else {
            this.$message({
              type: "error",
              message: "删除失败!",
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    onClosedialog() {
      this.addOrEditForm.levelId = "";
      this.addOrEditForm.title = "";
      this.addOrEditForm.integral = "";
      this.addOrEditForm.getIntegral = "";
      this.addOrEditForm.discount = "";
      this.addOrEditForm.backImg = "";
      this.addOrEditForm.relegation = "";
      
    },
    onChangeSort(row) {
      row.inputVisible = true;
    },
    async handleInputConfirm(row) {
      const { data } = await changeUserLevel({
        levelId: row.levelId,
        title: row.title,
      });
      row.inputVisible = false;
      this.getUserLevelList();
    },
    async onEditRow(row) {
      const { data } = await levelDetails({
        levelId: row.levelId,
      });
      console.log(data, "等级详情");
      this.addOrEditForm = data.data[0];
      this.dialogVisible = true;
    },
    onAdd(id) {
      this.addOrEditForm.lelvelId = id;
      this.dialogVisible = true;
    },
    async onOk() {
      if (
        this.addOrEditForm.title === "" ||
        this.addOrEditForm.integral === "" ||
        this.addOrEditForm.getIntegral === "" ||
        this.addOrEditForm.discount === "" ||
        this.addOrEditForm.backImg === "" ||
        this.addOrEditForm.relegation === "" 
     
      ) {
        return this.$message({
          message: "请把数据填写完整!",
          type: "error",
        });
      }
      const { data } = await addLevel(this.addOrEditForm);
      if (data.code == 0) {
        this.$message({
          message: data.msg,
          type: "success",
        });
      } else {
        this.$message({
          message: data.msg,
          type: "warning",
        });
      }
      this.getUserLevelList();
      this.dialogVisible = false;
      this.addOrEditForm.lelvelId = "";
      this.addOrEditForm.title = "";
      this.addOrEditForm.integral = "";
      this.addOrEditForm.getIntegral = "";
      this.addOrEditForm.discount = "";
      this.addOrEditForm.relegation = "";
      
    },

    receive(id){
      this.$router.push('/receiveVip?fatherId='+id)
    },
    onInput(value) {
      var reg = /^\+?[1-9][0-9]*$/;
      if (!reg.test(value)) {
         this.$message({
          type: "error",
          message: "请输入正整数",
        });
        this.addOrEditForm.integral = "";
        return false
      }
    },
    onInput3(value) {
      var reg = /^\+?[1-9][0-9]*$/;
      if (!reg.test(value)) {
         this.$message({
          type: "error",
          message: "请输入正整数",
        });
         this.addOrEditForm.relegation = "";
        return false
      }
    },
    onInput2(value) {
      console.log(value);
      var reg = /^([1-9][0-9]*|0)(\.([0-9]+)?[1-9])?$/;
      if (!reg.test(value)) {
        return this.$message({
          type: "error",
          message: "请输入正整数或者小数",
        });
      }
    },
    onInput4(value) {
      console.log(value);
      if ((value + "").indexOf(".") != -1) {
        this.$message({
          message: "请输入整数",
          type: "warning",
        });
      }
    },
    onInput1(value) {
      if (isNaN(value)) {
        this.$message({
          message: "请输入数字",
          type: "warning",
        });
      } else if (value < 1 || value > 100) {
        this.$message({
          message: "请输入1-100之间的正整数",
          type: "warning",
        });
      } else if ((value + "").indexOf(".") != -1) {
        this.$message({
          message: "请输入整数",
          type: "warning",
        });
      }
    },
    handleSizeChange(num) {
      this.list.pageSize = num;
      this.getUserLevelList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getUserLevelList();
    },
  },
};
</script>
<style lang="less" scoped>
.userLevelList {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .el-table {
    margin-top: 50px;
    .el-table__body-wrapper {
      .el-table__row {
        .button-new-tag {
          border: 0;
        }
      }
    }
  }
  .btn {
    margin-top: 20px;
    float: left;
  }
  .el-pagination {
    margin-top: 20px;
    float: right;
  }
  /deep/.el-form {
    .el-form-item {
      .el-form-item__content {
        .elbtn {
          margin-left: 10px;
          background-color: unset;
          width: 110px;
          height: 110px;
          text-align: center;
          line-height: 110px;
          color: #000;
          border: 1px solid #ccc;
        }
        .img {
          position: relative;
          margin: 0 20px;
          height: 100px;
          width: 100px;
          img {
            height: 100px;
            width: 100px;
            border: 1px solid #c0ccda;
          }
          div {
            position: absolute;
            top: 0;
            right: 5px;
            height: 10px;
            width: 10px;
            z-index: 100;
          }
        }
        .addImg {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px dashed #c0ccda;
          text-align: center;
          line-height: 100px;
          cursor: pointer;
          i {
            width: 50px;
            height: 50px;
            font-size: 20px;
            color: #c0ccda;
          }
        }
        span {
          margin-left: 30px;
          color: red;
        }
      }
    }
  }
  /deep/ .el-form {
    .el-form-item {
      display: flex;
      .el-form-item__content {
        width: 400px;
        margin-left: 20px !important;
      }
      .el-form-item__label {
        width: 140px !important;
      }
    }
  }
}
</style>